import React from "react";

const About = () => {
  return (
    <section id="about" className="about-container">
      <h1 id="title"> About Me</h1>
      <div className="about-text">
        <p>
          Hello! My name is Daniel Goncalves but most people call me Danny. I
          attend University of Massachusetts Boston. I am a Computer Science
          major in my senior year. I'm interested in both web development,
          mobile applications and currently looking for a full-time position. When im not coding, I enjoy working out, playing
          basketball, watching sports, and gaming.
        </p>
      </div>
    </section>
  );
};
export default About;
